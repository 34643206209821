<template>
  <el-table
    :data="filteredReport23.finalResult"
    border
    size="small"
    v-loading="reportsStore.table_loading"
    :row-style="tablerowstyle"
    class="h-100"
  >
    <el-table-column width="180" label="Remont">
      <el-table-column
        align="center"
        width="180"
        prop="get_truck_brand_model"
        label="Avtomashina"
      />

      <el-table-column
        align="center"
        width="180"
        prop="get_truck_number"
        label="Gos. nomer"
      />

      <el-table-column
        align="center"
        width="110"
        v-for="(item, index) in 12"
        :key="item + 1"
        :label="months[item - 1]"
      >
        <template slot-scope="scope">{{
          scope.row.remont_cost_amount_by_month &&
          scope.row.remont_cost_amount_by_month[index] &&
          toRoundFloat(scope.row.remont_cost_amount_by_month[index][item])
        }}</template>
      </el-table-column>

      <el-table-column
        align="center"
        width="180"
        prop="remont_cost_amount_year_total"
        label="Itogo"
      >
        <template slot-scope="scope">
          {{ toRoundFloat(scope.row.remont_cost_amount_year_total) }}
        </template>
      </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import monthNames from '@/constants/js/month.names.js'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat } from '@/utils'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport23']),
    months() {
      return monthNames || []
    }
  },

  methods: { toRoundFloat },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Remont xarajatlari' }])
    })
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
